import React from 'react'
import styled from 'styled-components'

const PaypalPaymentSuccess = () => {
  return (
    <PaypalPaymentSuccessWrapper>
      <div>
        <h2 className='mb-2'>Thank you for making a payment via PayPal!</h2>
        <div className='my-2'>
          <a href='https://omahalightningvolleyball.com'>
            Omaha Lightning Volleyball Website
          </a>
        </div>
        <div className='my-2'>
          <a href='/'>Omaha Lightning Volleyball Portal</a>
        </div>
      </div>
    </PaypalPaymentSuccessWrapper>
  )
}

export default PaypalPaymentSuccess

const PaypalPaymentSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.75rem;
  margin: 1.25rem;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 25rem;
    padding: 1.25rem;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 35rem;
    position: relative;
    top: -50px;
    width: 25rem;
    text-align: center;
  }
  > div > h2 {
    text-align: center;
  }
`
