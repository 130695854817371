import api from './axiosConfig'

const rootContentAdminUrl = '/ContentAdmin'
const rootContactAdminUrl = '/Contacts'

export const getPendingEmails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootContentAdminUrl + '/getReadyEmails')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getContacts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootContactAdminUrl)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getContactWebCredentials = (contactId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootContactAdminUrl + '/getWebsiteCredentialsForContact',
        { contactId }
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateContactWebsitePassword = (contactId, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootContactAdminUrl + '/resetPasswordForContact',
        {
          contactId,
          password
        }
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}
