import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { logOutUser } from '../store/userSlice'
import { messaging } from '../utils/toast'
import { GiHamburgerMenu } from 'react-icons/gi'
import { setShowMainNav } from '../store/appSlice'

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const handleLogout = () => {
    messaging.successMessage('You have successfully logged out!')
    dispatch(logOutUser())
    history.push('/')
  }
  return (
    <header className='main-header'>
      <div className='header-title'>
        <GiHamburgerMenu
          size={'25px'}
          style={{ margin: '5px' }}
          onClick={() => dispatch(setShowMainNav(true))}
        />
      </div>
      <h4 style={{ marginLeft: '.75rem' }}>OLVB Admin</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className='mr-1 user-formal-name'>
          <strong>{user.firstName + ' ' + user.lastName}</strong>
        </div>
        <Button size='tiny' onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </header>
  )
}

export default Header
