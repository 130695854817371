import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
import styled from 'styled-components'
import PayPal from '../components/PayPal'
import { v4 as uuidv4 } from 'uuid'
import PayByCheck from '../components/PayByCheck'
import CheckboxGroup from 'react-checkbox-group'
import * as Yup from 'yup'
import RegistrationSuccess from '../components/RegistrationSuccess'
import { register } from '../api/registrationApi'
import api from '../api/axiosConfig'

const League2022 = () => {
  const [showPayPal, setShowPayPal] = useState(false)
  const [showPayByCheck, setShowPayByCheck] = useState(false)
  const [registeredTeams, setRegisteredTeams] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [fetched, setFetched] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const processSubmit = (
    details = {
      payer: { email_address: '', name: { given_name: '', surname: '' } }
    }
  ) => {
    let total = 0.0
    selectedPayments.forEach((item) => {
      total += +item.amount.value
    })
    const paypalDetails = {
      paypalEmailAddress: details.payer.email_address,
      paypalPayerGivenName: details.payer.name.given_name,
      paypalPayerSurname: details.payer.name.surname
    }
    const paymentData = selectedPayments.map((pmt) => {
      return {
        details: pmt.amount.description,
        amount: pmt.amount.value,
        referenceId: pmt.reference_id,
        paid: details.payer.email_address === '' ? 0.0 : pmt.amount.value
      }
    })
    const submitData = {
      ...formData,
      ...paypalDetails,
      eventId: 1,
      totalAmount: total,
      payments: paymentData
    }
    //console.log(JSON.stringify(submitData))
    register(submitData)
      .then(() => {
        api.post('/EmailService/registrationReceived', {
          from: formData.organizationName,
          for: 'Fall League 2022',
          amount: total,
          payments: paymentData,
          email: formData.emailAddress
        })
        api.post('/EmailService/generateRegistrationResponseEmail', {
          emailTo: formData.emailAddress,
          eventName: 'Fall League 2022',
          organizationName: formData.organizationName
        })
        setShowSuccess(true)
      })
      .catch(() => {
        alert('Error!')
      })
  }

  const [formData, setFormData] = useState({
    emailAddress: '',
    organizationName: '',
    responsibilityAck: false,
    cleanupAfterAck: false,
    datesCannotAttend: '',
    comments: ''
  })

  const model = Yup.object().shape({
    organizationName: Yup.string().required(
      'The name of your program is required'
    ),
    emailAddress: Yup.string()
      .email('Please enter a valid email address')
      .required('Email address is required'),
    responsibilityAck: Yup.boolean()
      .oneOf([true], 'You must accept these terms')
      .required(),
    cleanupAfterAck: Yup.boolean()
      .oneOf([true], 'You must accept these terms')
      .required(),
    datesCannotAttend: Yup.string(),
    comments: Yup.string()
  })

  const createPayment = (payment) => {
    const payPalPmt = {
      amount: { description: payment.description, value: payment.amount },
      reference_id: uuidv4()
    }
    return payPalPmt
  }

  useEffect(() => {
    model
      .validate(formData, { abortEarly: false })
      .then((msg) => {
        setFormValid(true)
      })
      .catch((msg) => {
        setFormValid(false)
      })
  }, [formData, model])

  useEffect(() => {
    const payments = []
    registeredTeams.forEach((item) => {
      const pmt = createPayment(paymentTypes[item])
      payments.push(pmt)
    })
    setSelectedPayments(payments)
  }, [registeredTeams, paymentTypes])

  useEffect(() => {
    if (!fetched) {
      fetch(`/leaguePaymentsFall2022.js?timestamp=` + new Date(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then((data) => {
          return data.json()
        })
        .then((myJson) => {
          setPaymentTypes(myJson.paymentTypes)
          setFetched(true)
        })
    }
  }, [fetched])

  const handleChange = (_, values) => {
    setFormData((prev) => {
      return { ...prev, [values.name]: values.value }
    })
  }

  const handleCheckChange = (_, values) => {
    setFormData((prev) => {
      return { ...prev, [values.name]: values.checked }
    })
  }

  if (showSuccess) {
    return (
      <div className='page-wrapper'>
        <div
          style={{
            maxWidth: '35rem',
            margin: '.75rem auto 0 auto',
            padding: '.75rem .75rem 0 .75rem'
          }}
        >
          <RegistrationSuccess />
        </div>
      </div>
    )
  }

  return (
    <div className='page-wrapper'>
      <div
        style={{
          maxWidth: '90%',
          margin: '.75rem auto 0 auto',
          padding: '.75rem .75rem 0 .75rem'
        }}
      >
        <h2>Omaha Lightning Volleyball League Registration</h2>
        <h4>Fall 2022</h4>
      </div>
      <div style={{ padding: '1.25rem' }}>
        <p>
          You are invited to take part in our homeschool and Christian school
          volleyball league.
        </p>
        <div>
          Location: UBT Sports Complex, 21015 Cumberland Dr, Elkhorn, NE <br />
          Dates: September 13, 20, 27 and October 4, 11, 18, 2022 <br />
          Time: 6-9pm
          <h5 className='my-1'>Registration fees</h5>
          Varsity: $285 per team <br />
          JV: $235 per team
        </div>
        <p>
          Learn more:
          <br />
          <a
            href='https://omahalightningvolleyball.com/2022/05/24/league-nights/'
            target='_blank'
            rel='noreferrer'
          >
            https://omahalightningvolleyball.com/2022/05/24/league-nights/
          </a>
        </p>

        {!showPayPal && !showPayByCheck && (
          <Registration className='mt-1'>
            <h5 className='mb-1'>Registration:</h5>
            <Form>
              <Form.Input
                name='emailAddress'
                placeholder='Email (required)'
                onChange={handleChange}
                required
                value={formData.emailAddress}
              />
              <Form.Input
                className='my-1'
                name='organizationName'
                placeholder='Name of your volleyball program (required)'
                onChange={handleChange}
                required
                value={formData.organizationName}
              />
              <strong className='my-1'>Teams Registering:</strong>
              <div className='ui form'>
                <CheckboxGroup
                  name='registeredTeams'
                  value={registeredTeams}
                  onChange={setRegisteredTeams}
                >
                  {(Checkbox) => (
                    <div className='grouped fields'>
                      <div className='field'>
                        <div className='ui checkbox'>
                          <Checkbox value='0' />
                          <label>Varsity 1</label>
                        </div>
                      </div>
                      <div className='field'>
                        <div className='ui checkbox'>
                          <Checkbox value='1' />
                          <label>Varsity 2</label>
                        </div>
                        <div className='field'>
                          <div className='ui checkbox'>
                            <Checkbox value='2' />
                            <label>JV 1</label>
                          </div>
                        </div>
                      </div>
                      <div className='field'>
                        <div className='ui checkbox'>
                          <Checkbox value='3' />
                          <label>JV 2</label>
                        </div>
                      </div>
                      <div className='field'>
                        <div className='ui checkbox'>
                          <Checkbox value='4' />
                          <label>JV 3</label>
                        </div>
                      </div>
                    </div>
                  )}
                </CheckboxGroup>
              </div>
              <div className='bolder py-1'>
                Submitting this registration means that your teams accept
                responsibility for any injuries you (players and coaches) could
                sustain and that Omaha Lightning Volleyball and UBT Sports
                Center are not to be held liable for incidents occurring during
                league.
              </div>
              <Form.Field>
                <Checkbox
                  required
                  name='responsibilityAck'
                  onChange={handleCheckChange}
                  label='Yes we acknowledge and accept responsibility'
                  checked={formData.responsibilityAck}
                />
              </Form.Field>
              <Form.Input
                name='datesCannotAttend'
                onChange={handleChange}
                label="Are there any dates that you can't attend?"
                value={formData.datesCannotAttend}
              />
              <div className='bolder py-1'>
                By joining this league you are agreeing help clean up the courts
                after play. Teams will take turns and a schedule will be sent
                later.
              </div>
              <Form.TextArea
                name='comments'
                label="Do you have questions or suggestions? We'd love to hear from you..."
                onChange={handleChange}
                value={formData.comments}
              />
              <Button
                disabled={selectedPayments.length === 0 || !formValid}
                className='my-1'
                size='mini'
                color='blue'
                onClick={() => {
                  setShowPayPal(true)
                }}
              >
                Pay with PayPal or Card
              </Button>
              <Button
                disabled={selectedPayments.length === 0 || !formValid}
                className='ml-1 my-1'
                size='mini'
                color='green'
                onClick={() => {
                  setShowPayByCheck(true)
                }}
              >
                Pay By Check
              </Button>
            </Form>
          </Registration>
        )}
      </div>
      {showPayPal && (
        <div className='mb-2'>
          <PayPal
            submittedPayments={selectedPayments}
            showCustomAmount={false}
            showAddPaymentSelectBox={false}
            showClearButton={false}
            onPaymentSuccess={(details) => {
              processSubmit(details)
            }}
          />
          <Button
            style={{
              margin: '.75rem auto',
              display: 'block',
              position: 'relative',
              top: '-.75rem'
            }}
            color='red'
            onClick={() => {
              setShowPayPal(false)
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      {showPayByCheck && (
        <div className='mb-2'>
          <PayByCheck selectedPayments={selectedPayments} />
          <div style={{ display: 'flex', width: '200px', margin: '0 auto' }}>
            <Button
              style={{
                margin: '.75rem auto',
                display: 'block',
                position: 'relative',
                top: '-.75rem'
              }}
              color='green'
              onClick={() => {
                processSubmit()
              }}
            >
              Confirm
            </Button>
            <Button
              style={{
                margin: '.75rem auto',
                display: 'block',
                position: 'relative',
                top: '-.75rem'
              }}
              color='red'
              onClick={() => {
                setShowPayByCheck(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default League2022

const Registration = styled.div`
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
`
