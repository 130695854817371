import api from './axiosConfig'

const rootUrl = '/Registration'
const eventsAdminUrl = '/EventsAdmin'

export const register = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(rootUrl + '/register', frmData)
      if (res.data.status === 'success') {
        resolve(res.data)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getEvents = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootUrl + '/events')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getEventRegistrations = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        eventsAdminUrl + '/event_registrations/' + eventId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getEventRegistrationDetails = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        eventsAdminUrl + '/event_registration_details/' + eventId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const registerAthlete = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(rootUrl + '/registerAthlete', frmData)
      if (res.data.status === 'success') {
        resolve(res.data)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getAthleteRegistrations = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootUrl + '/getAthleteRegistrations')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}
