import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { currencyFormatter } from '../utils/numbers'

const PaymentsDisplay = ({ selectedPayments = [] }) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let total = 0.0
    selectedPayments.forEach((item) => {
      total += +item.amount.value
    })
    setTotal(total)
  }, [selectedPayments])

  return (
    <div style={{ marginTop: '1rem', maxWidth: '450px', minWidth: '300px' }}>
      {selectedPayments.length > 0 && <strong>Paying for:</strong>}
      <table style={{ width: '90%', margin: '10 auto' }}>
        <tbody>
          {selectedPayments.map((pmt) => {
            return (
              <tr key={pmt.reference_id}>
                <td
                  style={{
                    paddingRight: '1rem',
                    maxWidth: '250px'
                  }}
                >
                  {pmt.amount.description}
                </td>
                <td>{currencyFormatter(pmt.amount.value)}</td>
              </tr>
            )
          })}
          {selectedPayments.length === 0 && (
            <>
              <tr>
                <td
                  colSpan='2'
                  style={{ borderTop: '1px solid #ccc', height: '15px' }}
                ></td>
              </tr>
              <tr>
                <td colSpan='2'>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div className='error text-center mb-1'>No items added</div>
                    <div className='text-center'>
                      Please enter amount and description and click "Add Item"
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
          {selectedPayments.length > 0 && (
            <>
              <tr>
                <td
                  colSpan='2'
                  style={{ borderTop: '1px solid #ccc', height: '15px' }}
                ></td>
              </tr>
              <tr>
                <td>
                  <strong>Total:</strong>
                </td>
                <td>{currencyFormatter(total)}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PaymentsDisplay
