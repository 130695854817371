import React, { useState } from 'react'
import MyTextInput from './form/MyTextInput'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { addOrUpdateVBEvent, getVBEvent } from '../api/statsApi'
import { useEffect } from 'react'
import { messaging } from '../utils/toast'
import { Button } from 'semantic-ui-react'
import MyDateInput from './form/MyDateInput'

const VBEventForm = ({ onSuccess, cancelEvent, eventId = null }) => {
  const defaultEvent = {
    id: '',
    name: '',
    location: '',
    eventStartDate: new Date()
  }

  const [vbEvent, setVbEvent] = useState(defaultEvent)

  useEffect(() => {
    if (eventId) {
      getVBEvent(eventId).then((data) => {
        if (data.status === 'success') {
          setVbEvent({
            ...data.data,
            eventStartDate: data.data.eventStartDateUsShortDate
          })
        } else {
          messaging.errorMessage(data.error.message)
        }
      })
    }
  }, [eventId])

  useEffect(() => {
    const dateInput = document.querySelector('input[name="eventStartDate"')
    if (dateInput) {
      dateInput.onclick = (e) => {
        e.target.setAttribute('readonly', true)
      }
      dateInput.onblur = (e) => {
        e.target.removeAttribute('readonly')
      }
    }
  }, [])

  return (
    <div className='mb-2'>
      <Formik
        enableReinitialize={true}
        initialValues={vbEvent}
        validationSchema={Yup.object({
          name: Yup.string().required('Event Name is required'),
          location: Yup.string().required('Event Location is required'),
          eventStartDate: Yup.date().required('Event Date is required')
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const useDate = values.eventStartDate.toLocaleDateString
              ? values.eventStartDate.toLocaleDateString('en-US')
              : values.eventStartDate
            const useValues = { ...values, eventStartDate: useDate }
            addOrUpdateVBEvent(useValues)
              .then((data) => {
                if (data.status === 'success') {
                  messaging.successMessage(data.statusMessage)
                  resetForm({
                    values: defaultEvent
                  })
                  if (onSuccess) {
                    onSuccess({ ...useValues, id: data.data.id })
                  }
                } else {
                  messaging.errorMessage(data.error.message)
                }
              })
              .catch((err) => {
                messaging.errorMessage(err)
              })
          } catch (error) {
            messaging.errorMessage(error.message)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <>
            <h4>Enter Event Information</h4>
            <Form className='ui form'>
              <MyTextInput name='name' placeholder='Event Name' />
              <MyTextInput name='location' placeholder='Event Location' />
              <MyDateInput
                name='eventStartDate'
                placeholderText='Event date'
                dateFormat='MM/dd/yyyy'
                autocomplete='off'
              />
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                size='mini'
                positive
                content='Save'
              />
              <Button
                size='mini'
                color='red'
                content='Cancel'
                onClick={cancelEvent}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export default VBEventForm
