import React from 'react'
import styled from 'styled-components'

const RegistrationSuccess = () => {
  return (
    <RegistrationSuccessWrapper>
      <div>
        <h2>Thank you for registering!</h2>
        <div>
          We will send an email to the address provided to confirm your
          registration.
        </div>
        <div className='my-2'>
          <a href='https://omahalightningvolleyball.com'>
            Omaha Lightning Volleyball Website
          </a>
        </div>
      </div>
    </RegistrationSuccessWrapper>
  )
}

export default RegistrationSuccess

const RegistrationSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.75rem;
  margin: 1.25rem;
  min-height: 35rem;
  > div {
    position: relative;
    top: -50px;
    width: 25rem;
    text-align: center;
  }
  > div > h2 {
    text-align: center;
  }
`
