import React from 'react'
import styled from 'styled-components'
import PaymentsDisplay from './PaymentsDisplay'

const PayByCheck = ({ selectedPayments }) => {
  return (
    <PayByCheckWrapper>
      <div>
        Make checks payable to Omaha Lightning Volleyball. <br />
        <br />
        Mail to:
        <br />
        Omaha Lightning Volleyball
        <br />
        PO Box 644
        <br /> Elkhorn, NE 68022
      </div>
      <PaymentsDisplay selectedPayments={selectedPayments} />
    </PayByCheckWrapper>
  )
}

export default PayByCheck

const PayByCheckWrapper = styled.div`
  padding: 1.5rem;
  margin: 1.25rem;
  padding: 0.75rem;
  border: 1px solid #ccc;
`
