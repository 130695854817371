export const desktopNotify = (message, tag = null) => {
  navigator.serviceWorker.register('/sw.js')
  if (Notification && Notification.permission === 'granted') {
    let options = {}
    if (tag !== null) {
      options = { ...options, tag: tag }
    }
    navigator.serviceWorker.ready.then(function (registration) {
      return registration.showNotification(message, options)
    })
  }
}
