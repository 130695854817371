import { useEffect, useState } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import styled from 'styled-components'
import { messaging } from '../utils/toast'
import Spinner from 'react-spinkit'
import { Form, Button } from 'semantic-ui-react'
import Select from 'react-select'
import { currencyFormatter } from '../utils/numbers'
import { useRef } from 'react'
import PaymentsDisplay from './PaymentsDisplay'

const PayPal = ({
  showCustomAmount = true,
  paymentsName = 'payment-types',
  addPaymentPlaceholder = 'Add Payment Option',
  showAddPaymentSelectBox = true,
  showClearButton = true,
  submittedPayments = [],
  onPaymentSuccess = null
}) => {
  const customFormRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentTypes, setPaymentTypes] = useState([])
  const [dropDownData, setDropDownData] = useState([])
  const [fetched, setFetched] = useState(false)
  const [selectedPayments, setSelectedPayments] = useState([])
  const [showForm, setShowForm] = useState(true)
  const [title, setTitle] = useState('')

  const addPayment = (payment) => {
    const payPalPmt = {
      amount: { description: payment.description, value: payment.amount },
      reference_id: +new Date()
    }
    const pmts = [...selectedPayments, payPalPmt]
    setSelectedPayments(pmts)
    setIsLoading(false)
  }

  const addSelectedPayment = (paymentTypeId) => {
    const selectedPT = paymentTypes.find((elem) => {
      return elem.id === paymentTypeId
    })
    if (selectedPT) {
      addPayment(selectedPT)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    let isMounted = true
    if (!fetched) {
      fetch(`/${paymentsName}.js?timestamp=` + new Date(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then((data) => {
          if (isMounted) {
            return data.json()
          }
        })
        .then((myJson) => {
          if (isMounted) {
            setPaymentTypes(myJson.paymentTypes)
            setTitle(myJson.title)
            setFetched(true)
          }
        })
    }
    return () => {
      isMounted = false
    }
  }, [fetched, paymentsName])

  useEffect(() => {
    const ddItems = []
    paymentTypes.forEach((elem) => {
      const dItem = {}
      dItem.label = elem.description + ' - ' + currencyFormatter(elem.amount)
      dItem.value = elem.id
      ddItems.push(dItem)
    })
    setDropDownData(ddItems)
  }, [paymentTypes])

  useEffect(() => {
    if (submittedPayments.length > 0) {
      setSelectedPayments(submittedPayments)
    }
  }, [submittedPayments])

  const createOrder = (_, actions) => {
    return actions.order.create({
      purchase_units: selectedPayments
    })
  }

  return (
    <>
      <PayPalWrapper>
        {isLoading && (
          <Loading>
            <Spinner name='ball-grid-pulse' color='#ffcc00' fadeIn='none' />
          </Loading>
        )}
        <PayPalContent>
          <div id='form-wrapper'>
            {title !== '' && (
              <strong style={{ padding: '10px 0' }}>{title}</strong>
            )}
            <div
              style={{
                position: 'relative',
                top: '-10px',
                display: showForm ? 'block' : 'none'
              }}
            >
              <div
                style={{
                  margin: '10px 0',
                  borderBottom: '1px solid #ccc',
                  paddingBottom: '10px'
                }}
              >
                <>
                  {showAddPaymentSelectBox && (
                    <Select
                      id='input-picker'
                      value=''
                      onChange={(e) => {
                        setIsLoading(true)
                        addSelectedPayment(e.value)
                      }}
                      options={dropDownData}
                      style={{ width: 224 }}
                      placeholder={addPaymentPlaceholder}
                    />
                  )}
                  <div>
                    {showCustomAmount && (
                      <form className='ui form' ref={customFormRef}>
                        <p style={{ margin: '.5rem 0' }}>
                          <strong
                            style={{
                              display: showAddPaymentSelectBox
                                ? 'inline-block'
                                : 'none'
                            }}
                          >
                            OR ...{' '}
                          </strong>
                          <span
                            style={{
                              display: showAddPaymentSelectBox
                                ? 'inline-block'
                                : 'none'
                            }}
                          >
                            Enter Other amount:
                          </span>
                        </p>
                        <Form.Field>
                          <input
                            name='amount'
                            placeholder='Amount'
                            type='number'
                            inputMode='decimal'
                            required
                          />
                        </Form.Field>
                        <Form.Field>
                          <input
                            name='description'
                            placeholder='Description'
                            required
                          />
                        </Form.Field>
                      </form>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1rem'
                      }}
                    >
                      {showCustomAmount && (
                        <Button
                          size='mini'
                          primary
                          type='submit'
                          onClick={(e) => {
                            e.preventDefault()
                            const amount = customFormRef.current.amount.value
                            const description =
                              customFormRef.current.description.value
                            if (!amount || !description) {
                              messaging.errorMessage(
                                'Amount and Description are both required'
                              )
                            } else {
                              const id = '' + Date.now()
                              const payment = {
                                amount,
                                description,
                                id
                              }
                              setIsLoading(true)
                              addPayment(payment)
                              customFormRef.current.reset()
                            }
                          }}
                        >
                          Add Item
                        </Button>
                      )}
                      {showClearButton && selectedPayments.length > 0 && (
                        <Button
                          style={{ marginLeft: '1rem' }}
                          size='mini'
                          color='red'
                          onClick={() => {
                            setSelectedPayments([])
                          }}
                        >
                          Clear Items
                        </Button>
                      )}
                    </div>
                  </div>
                </>
                <PaymentsDisplay selectedPayments={selectedPayments} />
              </div>
            </div>
            {selectedPayments.length > 0 && (
              <PayPalButton
                onButtonReady={() => {
                  setIsLoading(false)
                }}
                onClick={(e) => {
                  if (selectedPayments.length <= 0) {
                    messaging.errorMessage('No Payment Options Selected')
                    return false
                  }
                  if (e.fundingSource !== 'card') {
                    setIsLoading(true)
                    setShowForm(true)
                  } else {
                    setShowForm(false)
                  }
                }}
                disableFunding='credit'
                createOrder={createOrder}
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                  setIsLoading(false)
                  const name =
                    details.payer.name.given_name +
                    ' ' +
                    details.payer.name.surname
                  messaging.successMessage('Transaction completed by ' + name)
                  if (onPaymentSuccess !== null) {
                    onPaymentSuccess(details, data, selectedPayments)
                  }
                  setSelectedPayments([])
                  //console.log('success: ' + JSON.stringify(details))
                }}
                options={{
                  clientId: process.env.REACT_APP_CLIENTID,
                  disableFunding: 'credit'
                }}
                onError={() => {
                  setIsLoading(false)
                  setShowForm(true)
                }}
                onCancel={() => {
                  setIsLoading(false)
                  setShowForm(true)
                }}
              />
            )}
          </div>
        </PayPalContent>
      </PayPalWrapper>
    </>
  )
}

export default PayPal

const PayPalWrapper = styled.div`
  padding: 1rem 0;
  margin: 0.75rem;
  position: relative;
`

const PayPalContent = styled.div`
  padding: 0.75rem 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-width: 23rem;
  min-height: 23rem;
  justify-content: center;
  z-index: 1;
  > #form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Loading = styled.div`
  z-index: 1000;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  background-color: #ccc;
`
