import React from 'react'
import { useField, useFormikContext } from 'formik'
import { FormField, Label } from 'semantic-ui-react'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

export default function MyDateInput({ label, ...props }) {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(props)
  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>
        {label}
        {props.required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <TimePicker
        autoComplete='off'
        inputReadOnly={true}
        use12Hours={true}
        showSecond={false}
        {...props}
        onChange={(value) => {
          const options = {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit'
          }

          const useTime =
            value === null ? '' : value._d.toLocaleTimeString('en-US', options)
          setFieldValue(field.name, useTime)
        }}
      />

      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  )
}
