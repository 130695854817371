import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { setShowInactiveAlert } from '../../store/appSlice'
import ModalWrapper from './ModalWrapper'

const InactiveAlert = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const navigateTo = (to) => {
    dispatch(setShowInactiveAlert(false))
    history.push(to)
  }
  return (
    <ModalWrapper
      style={{ width: '350px' }}
      size='mini'
      header='Session Timeout'
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <h4 style={{ textAlign: 'center' }}>
          You have been logged out due to inactivity
        </h4>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '250px'
          }}
        >
          <Button
            size='tiny'
            content='Portal'
            onClick={() => {
              navigateTo('/')
            }}
          />
          <Button
            size='tiny'
            content='Login'
            onClick={() => {
              navigateTo('/login')
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default InactiveAlert
