import { Formik, Form } from 'formik'
import React from 'react'
import ReactDom from 'react-dom'
import * as Yup from 'yup'
import MyTextInput from '../components/form/MyTextInput'
import { Button, Label } from 'semantic-ui-react'
import { login } from '../api/userApi'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from '../store/userSlice'
import { closeModal } from '../store/modalSlice'
import { NavLink } from 'react-router-dom'

export default function LoginForm() {
  const history = useHistory()

  const dispatch = useDispatch()

  return ReactDom.createPortal(
    <>
      <div className='portal-backdrop'></div>
      <div className='portal-modal' style={{ backgroundColor: '#aaa' }}>
        <div
          className='portal-content'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className='login-form'>
            <h3>Login</h3>
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={Yup.object({
                username: Yup.string().required(),
                password: Yup.string().required()
              })}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  const userData = await login(values)
                  dispatch(setUser({ ...userData.userInfo, loggedIn: true }))
                  setSubmitting(false)
                  dispatch(closeModal())
                  history.push('/adminHome')
                } catch (error) {
                  setErrors({ auth: 'Invalid username or password' })
                  setSubmitting(false)
                }
              }}
            >
              {({ isSubmitting, isValid, dirty, errors }) => (
                <Form className='ui form'>
                  <MyTextInput name='username' placeholder='Username' />
                  <MyTextInput
                    name='password'
                    placeholder='Password'
                    type='password'
                  />
                  {errors.auth && (
                    <Label
                      color='red'
                      style={{ marginBottom: 10 }}
                      content={errors.auth}
                    />
                  )}
                  <Button
                    loading={isSubmitting}
                    disabled={!dirty || !isValid || isSubmitting}
                    type='submit'
                    fluid
                    size='large'
                    color='teal'
                    content='Login'
                  />
                </Form>
              )}
            </Formik>
            <div style={{ margin: '1rem' }}>
              You are logging in to the admin site for Lightning Volleyball
              Stats and Registration. If you are looking for the public portal,
              you can go{' '}
              <strong>
                <NavLink to='/'>here.</NavLink>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('root')
  )
}
