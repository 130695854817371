import React, { useState } from 'react'
import MyTypeahead from './form/MyTypeahead'
import MySelectInput from './form/MySelectInput'
import MyCheckbox from './form/MyCheckbox'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  addOrUpdateVBEventMatch,
  getOpponents,
  getVBEventMatch,
  getVBTeams
} from '../api/statsApi'
import { useEffect } from 'react'
import { messaging } from '../utils/toast'
import { Button } from 'semantic-ui-react'
import MyDateInput from './form/MyDateInput'
import { useHistory } from 'react-router-dom'

const VBEventMatchForm = ({ vbEventId, cancelMatch, matchId = null }) => {
  const defaultMatch = {
    opponentName: '',
    isPoolPlay: false,
    matchDate: new Date(),
    vbTeamId: null,
    vbEventId: vbEventId
  }

  const [teams, setTeams] = useState([])
  const [vbEventMatch, setVbEventMatch] = useState(defaultMatch)
  const [opponents, setOpponents] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (matchId) {
      getVBEventMatch(matchId).then((data) => {
        if (data.status === 'success') {
          setVbEventMatch({
            ...data.data,
            matchDate: data.data.matchDateUsShortDate,
            isPoolPlay: data.data.isPoolPlayBool
          })
        } else {
          messaging.errorMessage(data.error.message)
        }
      })
    }
  }, [matchId])

  useEffect(() => {
    const dateInput = document.querySelector('input[name="matchDate"')
    if (dateInput) {
      dateInput.onclick = (e) => {
        e.target.setAttribute('readonly', true)
      }
      dateInput.onblur = (e) => {
        e.target.removeAttribute('readonly')
      }
    }
  }, [])

  useEffect(() => {
    getOpponents(vbEventId).then((data) => {
      if (data.status === 'success') {
        setOpponents(data.data)
      }
    })
    getVBTeams().then((data) => {
      if (data.status === 'success') {
        const sorted = data.data.sort((a, b) => {
          return a.sortOrder.localeCompare(b.sortOrder)
        })
        setTeams(
          sorted.map((res) => {
            return {
              key: res.id,
              text: res.name,
              value: res.id
            }
          })
        )
      }
    })
  }, [vbEventId])

  return (
    <div className='mb-2 fadeInDown'>
      <Formik
        enableReinitialize={true}
        initialValues={vbEventMatch}
        validationSchema={Yup.object({
          opponentName: Yup.string().required('Opponent Name is required'),
          isPoolPlay: Yup.bool(),
          matchDate: Yup.date().required('Match Date is required'),
          vbTeamId: Yup.number().required('Team is required')
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const useDate = values.matchDate.toLocaleDateString
              ? values.matchDate.toLocaleDateString('en-US')
              : values.matchDate
            const useValues = {
              ...values,
              matchDate: useDate,
              isPoolPlay: values.isPoolPlay ? 1 : 0
            }
            addOrUpdateVBEventMatch(useValues)
              .then((data) => {
                if (data.status === 'success') {
                  messaging.successMessage(data.statusMessage)
                  // resetForm({
                  //   values: defaultMatch
                  // })
                  // addMatch({ ...useValues, id: data.data.id })
                  history.push('/eventMatch/' + data.data.id)
                } else {
                  messaging.errorMessage(data.error.message)
                }
              })
              .catch((err) => {
                messaging.errorMessage(err)
              })
          } catch (error) {
            messaging.errorMessage(error.message)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <>
            <h4>Enter Match Information</h4>
            <Form className='ui form'>
              <MyTypeahead
                name='opponentName'
                placeholder='Opponent Name'
                items={opponents}
              />
              {/*<MyTextInput name='opponentName' placeholder='Opponent Name' />*/}
              <MySelectInput
                name='vbTeamId'
                placeholder='Team'
                options={teams}
              />
              <div className='m-1'>
                <MyCheckbox name='isPoolPlay'>Is Pool Play?</MyCheckbox>
              </div>
              <MyDateInput
                label='Match Date'
                name='matchDate'
                placeholderText='Match date'
                dateFormat='MM/dd/yyyy'
                autocomplete='off'
              />
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                size='mini'
                positive
                content='Save'
              />
              <Button
                size='mini'
                color='red'
                content='Cancel'
                onClick={cancelMatch}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export default VBEventMatchForm
