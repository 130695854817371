import React, { useEffect, useRef } from 'react'
import { useField } from 'formik'
import { FormField, Label } from 'semantic-ui-react'

export default function MyScoreInput({
  label,
  onEnterClicked,
  shouldFocus,
  ...props
}) {
  const [field, meta, helpers] = useField(props)
  const focusField = useRef(null)

  useEffect(() => {
    if (shouldFocus) {
      focusField.current.focus()
    }
  }, [shouldFocus])

  const addOne = () => {
    if (field) {
      const newVal = +field.value + 1
      helpers.setValue(newVal)
    }
  }

  const subtractOne = () => {
    if (field) {
      const newVal = +field.value - 1
      if (newVal <= 0) {
        helpers.setValue(0)
        return
      }
      helpers.setValue(newVal)
    }
  }

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>
        {label}
        {props.required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button type='button' onClick={subtractOne} className='m-1'>
          -
        </button>
        <input
          ref={focusField}
          style={{ width: '3.5rem' }}
          {...field}
          {...props}
          onFocus={(e) => {
            e.target.select()
          }}
          onKeyPress={(e) => {
            if (onEnterClicked) {
              if (e.key === 'Enter' && e.target.value > 0) {
                e.preventDefault()
                onEnterClicked()
              }
            }
          }}
        />
        <button type='button' onClick={addOne} className='m-1'>
          +
        </button>
      </div>
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  )
}
