import React, { useEffect, useState } from 'react'
import { getPendingEmails } from '../api/adminApi'

const EmailMonitor = () => {
  const [pendingEmails, setPendingEmails] = useState([])

  useEffect(() => {
    getPendingEmails().then((data) => {
      setPendingEmails(data.data)
    })
  }, [])

  return (
    <div>
      <h1>Emails pending</h1>
      {pendingEmails.length === 0 && <h3>No Emails Pending</h3>}
      {pendingEmails.length > 0 && (
        <ul>
          {pendingEmails.map((email) => {
            return (
              <li key={email.id}>
                {email.sendToEmail + ' : ' + email.sendToFormal}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default EmailMonitor
