import { useState } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import CheckboxGroup from 'react-checkbox-group'
import * as Yup from 'yup'
import RegistrationSuccess from '../components/RegistrationSuccess'
import { registerAthlete } from '../api/registrationApi'
import api from '../api/axiosConfig'
import MyTextInput from '../components/form/MyTextInput'
import { Formik, Form } from 'formik'
import MySelectInput from '../components/form/MySelectInput'
import { messaging } from '../utils/toast'
import { parse, isDate } from 'date-fns'

const AthleteRegistrationEnter = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [selectedDivisions, setSelectedDivisions] = useState([])
  const [waiverOpened, setWaiverOpened] = useState(false)

  const parseDateString = (value, originalValue) => {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, 'MM/dd/yyyy', new Date())

    return parsedDate
  }

  if (showSuccess) {
    return (
      <div className='page-wrapper'>
        <div
          style={{
            maxWidth: '35rem',
            margin: '.75rem auto 0 auto',
            padding: '.75rem .75rem 0 .75rem'
          }}
        >
          <RegistrationSuccess />
        </div>
      </div>
    )
  }

  return (
    <div className='page-wrapper'>
      <div
        style={{
          maxWidth: '90%',
          margin: '.75rem auto 0 auto',
          padding: '.75rem .75rem 0 .75rem'
        }}
      >
        <h2>Omaha Lightning ATHLETE REGISTRATION</h2>
        <p>
          Fields marked with an <span style={{ color: 'red' }}>*</span> are
          required.
        </p>
        <p>
          {' '}
          Only parents, legal guardians, or the players themselves may fill out
          this form. If you are not a parent, legal guardian, or a player, do
          not fill out this form.
        </p>
      </div>
      <div style={{ padding: '20px' }}>
        <Registration className='mt-1'>
          <Formik
            enableReinitialize={true}
            initialValues={{
              eventName: '',
              name: '',
              gender: '',
              birthDay: '',
              team: '',
              emailAddress: '',
              phone: '',
              waiverAccepted: '',
              signature: ''
            }}
            validationSchema={Yup.object({
              birthDay: Yup.date()
                .transform(parseDateString)
                .typeError('Please enter date in the format "MM/DD/YYYY"')
                .required('Birthday is required'),
              team: Yup.string().required('Team name is required'),
              eventName: Yup.string().required('Event name is required'),
              name: Yup.string().required('Athlete name is required'),
              gender: Yup.string().required('Gender is required'),
              emailAddress: Yup.string()
                .email('Valid email address is required')
                .required('Valid email address is required'),
              phone: Yup.string().required('Phone number is required'),
              signature: Yup.string().required('Signature is required'),
              waiverAccepted: Yup.string()
                .matches(/(yes|Yes)/, "Please enter 'yes' or 'Yes'")
                .required(
                  "You must accept the liability waiver by typing 'yes'"
                )
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (selectedDivisions.length === 0) {
                messaging.errorMessage('Please select at least one division')
                return
              }
              try {
                const useDate = values.birthDay.toLocaleDateString
                  ? values.birthDay.toLocaleDateString('en-US')
                  : values.birthDay

                const useValues = {
                  ...values,
                  divisions: selectedDivisions.join(','),
                  birthDay: useDate
                }

                registerAthlete(useValues)
                  .then(() => {
                    api.post(
                      '/EmailService/generateRegistrationResponseEmail',
                      {
                        emailTo: useValues.emailAddress,
                        eventName: useValues.eventName,
                        organizationName: useValues.name
                      }
                    )
                    setShowSuccess(true)
                    setSubmitting(false)
                  })
                  .catch(() => {
                    alert('Error!')
                  })
              } catch (err) {
                alert(err)
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <>
                <h4>Enter Athlete Registration Information</h4>
                <Form className='ui form'>
                  <MyTextInput
                    name='eventName'
                    label='Event Name'
                    required
                    autoComplete='off'
                  />
                  <div className='ui form my-1'>
                    <label>
                      <strong>
                        Divisions<span style={{ color: 'red' }}>*</span>
                      </strong>
                    </label>
                    <CheckboxGroup
                      name='divisions'
                      label='Divisions'
                      onChange={setSelectedDivisions}
                      value={selectedDivisions}
                      required
                    >
                      {(Checkbox) => (
                        <div className='grouped fields'>
                          <div className='field'>
                            <div className='ui checkbox'>
                              <Checkbox value='Varsity' />
                              <label>Varsity</label>
                            </div>
                          </div>
                          <div className='field'>
                            <div className='ui checkbox'>
                              <Checkbox value='JV' />
                              <label>JV</label>
                            </div>
                            <div className='field'>
                              <div className='ui checkbox'>
                                <Checkbox value='Boys Varsity' />
                                <label>Boys Varsity</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </CheckboxGroup>
                  </div>
                  <MyTextInput
                    name='team'
                    label='Team Name (Please ask your coach for this name)'
                    autoComplete='off'
                    required
                  />
                  <MyTextInput
                    name='name'
                    label='Athlete Name'
                    required
                    autoComplete='off'
                  />
                  <MySelectInput
                    name='gender'
                    label='Gender'
                    required
                    options={[
                      { key: 1, text: 'Male', value: 'Male' },
                      { key: 2, text: 'Female', value: 'Female' }
                    ]}
                  />
                  <MyTextInput
                    name='birthDay'
                    label='Birth Day (MM/DD/YYYY)'
                    required
                    autoComplete='off'
                  />
                  <MyTextInput name='phone' label='Phone' required />
                  <MyTextInput
                    name='emailAddress'
                    label='Email Address'
                    required
                    autoComplete='off'
                  />
                  <div className='m-1'>
                    <a
                      className='hover-link'
                      href='/olvb-liability-waiver.pdf'
                      target='_blank'
                      onClick={() => {
                        setWaiverOpened(true)
                      }}
                    >
                      <strong>
                        Omaha Lightning Tournament Liability Waiver
                        <span style={{ color: 'red' }}>*</span>
                      </strong>
                    </a>
                    <p>
                      You must click on the link above and agree to the waiver
                      before electronically signing
                    </p>
                  </div>
                  <MyTextInput
                    disabled={!waiverOpened}
                    name='waiverAccepted'
                    label='Do you agree to the liability waiver?'
                    required
                    autoComplete='off'
                  />
                  <MyTextInput
                    disabled={!waiverOpened}
                    name='signature'
                    label='Type Electronic Signature (must be 18 years old)'
                    required
                    autoComplete='off'
                  />
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting || !isValid || !dirty}
                    type='submit'
                    size='mini'
                    positive
                    content='Save'
                  />
                </Form>
              </>
            )}
          </Formik>
        </Registration>
      </div>
    </div>
  )
}

export default AthleteRegistrationEnter

const Registration = styled.div`
  padding: 0.75rem;
  margin: 0.35rem;
  border: 1px solid #ccc;
`
