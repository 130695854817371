import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Divider } from 'semantic-ui-react'
import {
  deleteVBSet,
  getVBEvent,
  getVBEventMatch,
  getVBSets,
  getVBTeam
} from '../api/statsApi'
import Loading from './Loading'
import VBSetForm from './VBSetForm'
import { messaging } from '../utils/toast'
import { RiDeleteBin2Line } from 'react-icons/ri'
import EventsNavBar from './EventsNavBar'
import './VbEventMatch.css'
import Ably from '../api/ablyApi'

const channel = Ably.channels.get('remove-sets')

const VBEventMatch = ({ isPublic = false, vbMatchId }) => {
  const [team, setTeam] = useState({ name: '' })
  const [event, setEvent] = useState({ name: '' })
  const [vbMatch, setVbMatch] = useState(null)
  const [showSetForm, setShowSetForm] = useState(false)
  const [sets, setSets] = useState([])
  const [defaultSetId, setDefaultSetId] = useState(1)

  const addSet = (data) => {
    setSets([...sets, data])
    setShowSetForm(false)
    setShowSetForm(true)
  }

  const cancelSet = () => {
    setShowSetForm(false)
  }

  useEffect(() => {
    if (vbMatchId) {
      getVBEventMatch(vbMatchId).then((data) => {
        if (data.status === 'success') {
          setVbMatch(data.data)
          getVBTeam(data.data.vbTeamId).then((data) => {
            if (data.status === 'success') {
              setTeam(data.data)
            }
          })
          getVBEvent(data.data.vbEventId).then((data) => {
            if (data.status === 'success') {
              setEvent(data.data)
            }
          })
          getVBSets(vbMatchId).then((data) => {
            if (data.status === 'success') {
              setSets(data.data)
            }
          })
        } else {
          messaging.errorMessage(data.error.message)
        }
      })
    }
  }, [vbMatchId])

  useEffect(() => {
    let maxSetId = 1
    sets.forEach((set) => {
      const useSetId = +set.setId
      if (useSetId >= maxSetId) {
        maxSetId = useSetId + 1
      }
    })
    setDefaultSetId(maxSetId)
  }, [sets])

  if (vbMatch === null) {
    return <Loading />
  }
  return (
    <div className='animate__animated animate__fadeInDown'>
      <EventsNavBar urlPrevix={isPublic ? '/public' : ''} eventId={event.id} />
      <h4>Event: {event.name}</h4>
      <Divider />
      <div style={{ display: 'flex' }}>
        <h5 style={{ marginTop: '.25rem', marginRight: '1rem' }}>
          {' '}
          Team: {team.name}
        </h5>
        <h5 style={{ marginTop: '.25rem' }}>
          {' '}
          Opponent: {vbMatch.opponentName}
        </h5>
      </div>
      {!isPublic && !showSetForm && (
        <h5 className='mt-1'>
          Sets{' '}
          <Button
            size='mini'
            className='ml-1'
            content='Add Set'
            onClick={() => {
              setShowSetForm(true)
            }}
          />
        </h5>
      )}

      {showSetForm && (
        <div
          className='animate__animated animate__fadeInDown'
          style={{
            border: '1px solid #ccc',
            borderRadius: '3px',
            maxWidth: '450px',
            padding: '0 1rem'
          }}
        >
          <VBSetForm
            addSet={addSet}
            cancelSet={cancelSet}
            vbEventMatchId={vbMatch.id}
            defaultSetId={defaultSetId}
            onClose={() => {
              setShowSetForm(false)
            }}
          />
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th>Set</th>
            <th>Lightning</th>
            <th>Opponent</th>
            <th>Win/Loss</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {sets.map((set) => {
            return (
              <tr key={set.id}>
                <td className='text-center'>{set.setId}</td>
                <td className='text-center'>{set.score}</td>
                <td className='text-center'>{set.opponentScore}</td>
                <td className='text-center'>
                  {+set.score - +set.opponentScore > 0 ? 'W' : 'L'}
                </td>
                <td className='text-center'>
                  {!isPublic && !showSetForm && (
                    <RiDeleteBin2Line
                      style={{ cursor: 'pointer' }}
                      title='Delete Set'
                      color='red'
                      onClick={() => {
                        deleteVBSet(set.id).then((data) => {
                          if (data.status === 'success') {
                            messaging.successMessage('Successfully deleted set')
                            const newSets = sets.filter((thisSet) => {
                              return set.id !== thisSet.id
                            })
                            setSets(newSets)
                            channel.publish('remove-sets', {
                              matchId: vbMatch.id
                            })
                          }
                        })
                      }}
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default VBEventMatch
