import React from 'react'
import { useState } from 'react'
import './MyTypeahead.css'
import { useField, useFormikContext } from 'formik'
import { Label } from 'semantic-ui-react'
import { useEffect } from 'react'

const MyTypeahead = ({ items, label, ...props }) => {
  const [field, meta] = useField(props)
  const [text, setText] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const { setFieldValue } = useFormikContext()

  const onTextChange = (e) => {
    const value = e.target.value
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, `i`)
      setSuggestions(items.sort().filter((v) => regex.test(v)))
      setText(value)
      setFieldValue(field.name, value)
    } else {
      setSuggestions([])
      setText('')
      setFieldValue(field.name, '')
    }
  }

  const suggestionSelected = (value) => {
    setText(value)
    setFieldValue(field.name, value)
    setSuggestions([])
  }

  const Suggestions = ({ suggestions }) => {
    if (suggestions.length === 0) {
      return null
    }
    return (
      <ul>
        {suggestions.map((chosen) => (
          <li key={chosen} onClick={(e) => suggestionSelected(chosen)}>
            {chosen}
          </li>
        ))}
      </ul>
    )
  }

  useEffect(() => {
    if (field && field.value) {
      setText(field.value)
    }
  }, [field])

  return (
    <div className='TypeAheadDropDown'>
      <label>
        {label}
        {props.required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <input
        {...field}
        {...props}
        onBlur={() => {
          // Need just a touch of time to let click happen
          // before emptying suggestions
          setTimeout(() => {
            setSuggestions([])
          }, 500)
        }}
        onChange={onTextChange}
        value={text}
        type='text'
        autoComplete={'off'}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
      <Suggestions suggestions={suggestions} />
    </div>
  )
}

export default MyTypeahead
