import React, { useState } from 'react'
import { useEffect } from 'react'
import { BiEdit } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { getVBEvents } from '../api/statsApi'
import VBEventForm from './VBEventForm'

const VBEvents = ({ isPublic = false }) => {
  const [events, setEvents] = useState([])
  const [showEventForm, setShowEventForm] = useState(false)
  const [editEVentId, setEditEventId] = useState(null)

  const addEvent = (event) => {
    let filtered = events.filter((evt) => {
      return +event.id !== +evt.id
    })
    const useDate = new Date(event.eventStartDate).getTime()
    event.eventStartDateTimestamp = useDate
    filtered = [...filtered, event]
    filtered.sort((a, b) => {
      return b.eventStartDateTimestamp - a.eventStartDateTimestamp
    })
    setEvents(filtered)
    setEditEventId(null)
    setShowEventForm(false)
  }

  const cancelEvent = () => {
    setEditEventId(null)
    setShowEventForm(false)
  }

  useEffect(() => {
    let isMounted = true
    getVBEvents().then((data) => {
      if (isMounted) {
        data.data.sort((item1, item2) => {
          return item1.eventStartDateTimestamp < item2.eventStartDateTimestamp
            ? 1
            : -1
        })
        setEvents(data.data)
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className='mr-1'>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        {!isPublic && (
          <Button
            style={{ display: showEventForm ? 'none' : 'inline-block' }}
            size='mini'
            onClick={() => {
              setShowEventForm(true)
            }}
          >
            Add Event
          </Button>
        )}
      </div>
      {showEventForm && (
        <VBEventForm
          eventId={editEVentId}
          onSuccess={addEvent}
          cancelEvent={cancelEvent}
        />
      )}

      <div className='m-2'>
        {events.length === 0 && <h3>No Events Found</h3>}
        {events.length > 0 &&
          events.map((event) => {
            return <div key={event.id}>{event.event}</div>
          })}
      </div>
      {!showEventForm && (
        <div className='mr-1'>
          <table>
            <thead>
              <tr>
                <th>Event</th>
                <th>&nbsp;</th>
                <th>Location</th>
                <th>Event Date</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => {
                return (
                  <tr key={event.id}>
                    <td>
                      <NavLink
                        to={
                          (isPublic ? '/public' : '') +
                          '/statsEvent/' +
                          event.id
                        }
                      >
                        <strong>{event.name}</strong>
                      </NavLink>
                    </td>
                    <td>
                      {!isPublic && (
                        <BiEdit
                          style={{ cursor: 'pointer' }}
                          title='Edit Event Info'
                          color='#e56908'
                          size='17px'
                          onClick={() => {
                            setEditEventId(event.id)
                            setShowEventForm(true)
                          }}
                        />
                      )}
                    </td>
                    <td>{event.location}</td>
                    <td>
                      {event.eventStartDateUsShortDate || event.eventStartDate}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default VBEvents
