import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getAthleteRegistrations } from '../api/registrationApi'
import { Table } from '../components/tables/SortableTable'
import './AthleteRegistration.css'
import './Registration.css'

const AthleteRegistration = () => {
  const [registrations, setRegistrations] = useState([])

  useEffect(() => {
    getAthleteRegistrations().then((data) => {
      setRegistrations(data)
    })
  }, [setRegistrations])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Athlete Registration Info',
        columns: [
          {
            Header: 'Id',
            accessor: 'id',
            show: false
          },
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Event Name',
            accessor: 'eventName'
          },
          {
            Header: 'Team',
            accessor: 'team'
          },
          {
            Header: 'Date',
            accessor: 'enteredOnUsShortDate'
          }
        ]
      }
    ],
    []
  )

  return (
    <div>
      <h2>Athlete Registration Info</h2>
      {registrations.length === 0 && (
        <h3 className='ml-2 mr-2 p-1 alert-error'>
          No registration records found for athletes
        </h3>
      )}
      {registrations.length > 0 && (
        <div className='ml-2'>
          <Table
            classToUse='athlete-registration'
            columns={columns}
            data={registrations}
          />
        </div>
      )}
    </div>
  )
}

export default AthleteRegistration
