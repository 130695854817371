import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getEventRegistrationDetails, getEvents } from '../api/registrationApi'
import Select from 'react-select'
import { useHistory } from 'react-router'
import './Registration.css'

const EventRegistration = ({ match }) => {
  const [registrations, setRegistrations] = useState([])
  const [dropDownData, setDropDownData] = useState([])
  const [eventDetails, setEventDetails] = useState([])
  const [events, setEvents] = useState([])
  const [eventName, setEventName] = useState('')
  const history = useHistory()

  const selectEvent = (eventId) => {
    history.push('/registration/' + eventId)
  }

  useEffect(() => {
    if (match.params.id) {
      getEvents(match.params.id).then((data) => {
        setEvents(data)
      })
      getEventRegistrationDetails(match.params.id).then((data) => {
        setRegistrations(data)
      })
    } else {
      setEventName('')
      setRegistrations([])
    }
  }, [match.params.id, setRegistrations])

  useEffect(() => {
    if (events.length > 0) {
      events.forEach((elem) => {
        if (elem.id === match.params.id) {
          setEventName(elem.name)
        }
      })
    }
  }, [match.params.id, events])

  useEffect(() => {
    const ddItems = []
    eventDetails.forEach((elem) => {
      const dItem = {}
      dItem.label = elem.name
      dItem.value = elem.id
      ddItems.push(dItem)
    })
    setDropDownData(ddItems)
  }, [eventDetails, setDropDownData])

  useEffect(() => {
    getEvents().then((data) => {
      setEventDetails(data)
    })
  }, [setEventDetails])

  return (
    <div>
      <h2>Registration Info</h2>
      <div>
        <Select
          id='input-picker'
          value=''
          onChange={(e) => {
            selectEvent(e.value)
          }}
          options={dropDownData}
          style={{ width: 224 }}
          placeholder='Select Event'
        />
      </div>
      <h3 className='ml-2 mt-2'>{eventName}</h3>
      {registrations.length === 0 && eventName !== '' && (
        <h3 className='ml-2 mr-2 p-1 alert-error'>
          No registration records found for event
        </h3>
      )}
      {registrations.length > 0 && (
        <div className='ml-2'>
          <table>
            <thead>
              <th>Org. Name</th>
              <th>Team Registered</th>
              <th>Amt Due</th>
              <th>Amt Paid</th>
            </thead>
            <tbody>
              {registrations.map((reg) => {
                return (
                  <tr key={reg.id}>
                    <td>{reg.organization_name}</td>
                    <td>{reg.details}</td>
                    <td>{reg.amount}</td>
                    <td>{reg.paid}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default EventRegistration
