import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Checkbox, Divider } from 'semantic-ui-react'
import {
  getVBEvent,
  getVBEventMatchesWithResults,
  getVBTeams
} from '../api/statsApi'
import VBEventMatchForm from './VBEventMatchForm'
import { messaging } from '../utils/toast'
import { BiEdit } from 'react-icons/bi'
import Ably from '../api/ablyApi'

const VBEvent = ({ isPublic = false, vbEventId }) => {
  const [event, setEvent] = useState({
    id: null,
    name: '',
    location: '',
    eventStartDate: '',
    sets: []
  })
  const [matches, setMatches] = useState([])
  const [loading, setLoading] = useState(true)
  const [showMatchForm, setShowMatchForm] = useState(false)
  const [teams, setTeams] = useState([])
  const [selectedTeamId, setSelectedTeamId] = useState(0)
  const [filteredMatches, setFilteredMatches] = useState([])
  const [editMatchId, setEditMatchId] = useState(null)
  const [showPoolPlay, setShowPoolPlay] = useState(true)
  const [showRegularPlay, setShowRegularPlay] = useState(true)

  const flashItem = (matchId) => {
    setTimeout(() => {
      const itemToFlash = document.getElementById(`match-${matchId}-sets`)
      if (itemToFlash) {
        itemToFlash.classList.add('animate__flash')
        itemToFlash.classList.add('animate__animated')
      }
    }, 1000)

    setTimeout(() => {
      const itemToFlash = document.getElementById(`match-${matchId}-sets`)
      if (itemToFlash) {
        itemToFlash.classList.remove('animate__animated')
        itemToFlash.classList.remove('animate__flash')
      }
    }, 3000)
  }

  const getFormattedSetInfo = (set) => {
    const diff = +set.score - +set.opponentScore
    const setScore = `${set.score} - ${set.opponentScore}`
    const winOrLoss = diff > 0 ? 'W' : 'L'
    return (
      <span key={set.id} style={{ color: winOrLoss === 'W' ? 'green' : 'red' }}>
        ({setScore} - {winOrLoss}){' '}
      </span>
    )
  }

  useEffect(() => {
    const removeChannel = Ably.channels.get('remove-sets')
    const updatesChannel = Ably.channels.get('update-sets')
    updatesChannel.subscribe('update-sets', (resp) => {
      messaging.successMessage('New sets entered')
      getVBEventMatchesWithResults(vbEventId).then((data) => {
        if (data.status === 'success') {
          setMatches(
            data.data.map((data) => {
              return { ...data, flash: +data.id === +resp.data.matchId }
            })
          )
          flashItem(resp.data.matchId)
        }
      })
    })

    removeChannel.subscribe('remove-sets', (resp) => {
      console.log('results', resp)
      messaging.errorMessage('Sets deleted')
      getVBEventMatchesWithResults(vbEventId).then((data) => {
        if (data.status === 'success') {
          setMatches(
            data.data.map((data) => {
              return { ...data, flash: +data.id === +resp.data.matchId }
            })
          )
          flashItem(resp.data.matchId)
        }
      })
    })

    return () => {
      updatesChannel.unsubscribe()
      removeChannel.unsubscribe()
    }
  }, [vbEventId])

  const addMatch = (match) => {
    const filtered = matches.filter((fltMatch) => {
      return match.id !== fltMatch.id
    })
    setMatches([...filtered, match])
    setEditMatchId(null)
    setShowMatchForm(false)
  }

  const cancelMatch = () => {
    setShowMatchForm(false)
    setEditMatchId(null)
  }

  const getTeamName = (teamId) => {
    const found = teams.filter((team) => {
      return +team.id === +teamId
    })
    if (found && found.length > 0) {
      return found[0].name
    }
    return ''
  }

  useEffect(() => {
    getVBTeams().then((data) => {
      if (data.status === 'success') {
        const sorted = data.data.sort((a, b) => {
          return a.sortOrder.localeCompare(b.sortOrder)
        })
        setTeams(sorted)
      }
    })
    if (vbEventId) {
      getVBEvent(vbEventId).then((data) => {
        if (data.status === 'success') {
          setEvent(data.data)
          getVBEventMatchesWithResults(data.data.id).then((data) => {
            if (data.status === 'success') {
              setMatches(data.data)
            }
          })
          setLoading(false)
        } else {
          messaging.errorMessage(data.error.message)
          setLoading(false)
        }
      })
    }
  }, [vbEventId])

  useEffect(() => {
    let filtered = []
    filtered = matches.filter((match) => {
      let show = selectedTeamId === 0 || match.vbTeamId - selectedTeamId === 0
      if (match.isPoolPlayBool) {
        return show && showPoolPlay
      }
      if (!match.isPoolPlayBool) {
        return show && showRegularPlay
      }
      return show
    })
    setFilteredMatches(filtered)
  }, [matches, selectedTeamId, showPoolPlay, showRegularPlay])

  if (loading) return <h1>Loading Event...</h1>

  return (
    <div>
      <NavLink to='/statsHome'>Back to Events</NavLink>
      <h1>Event: {event.name} </h1>
      <Divider />
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 className='mb-0 mr-1'>Matches</h2>

          <Checkbox
            className='m-1'
            label='Pool'
            checked={showPoolPlay}
            onClick={() => {
              setShowPoolPlay(!showPoolPlay)
            }}
          />
          <Checkbox
            className='m-1'
            label='Regular'
            checked={showRegularPlay}
            onClick={() => {
              setShowRegularPlay(!showRegularPlay)
            }}
          />
        </div>
        {!isPublic && (
          <Button
            style={{ display: showMatchForm ? 'none' : 'inline-block' }}
            size='mini'
            onClick={() => {
              setShowMatchForm(true)
            }}
          >
            Add Match
          </Button>
        )}
      </div>
      {event.id !== null && showMatchForm && (
        <VBEventMatchForm
          matchId={editMatchId}
          vbEventId={event.id}
          addMatch={addMatch}
          cancelMatch={cancelMatch}
        />
      )}

      {!showMatchForm && (
        <div className='m-1'>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button
              className='mb-1'
              onClick={() => {
                setSelectedTeamId(0)
              }}
              size='mini'
              content='All'
              active={selectedTeamId === 0}
              color={selectedTeamId === 0 ? 'green' : 'grey'}
            />
            {teams.length > 0 &&
              teams.map((team) => {
                return (
                  <Button
                    className='mb-1'
                    key={team.id}
                    onClick={() => {
                      setSelectedTeamId(team.id)
                    }}
                    size='mini'
                    content={team.name}
                    active={selectedTeamId - team.id === 0}
                    color={selectedTeamId - team.id === 0 ? 'green' : 'grey'}
                  />
                )
              })}
          </div>
          {filteredMatches.length === 0 && <h3>No Matches Found</h3>}
          {filteredMatches.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Opponent</th>
                  <th>&nbsp;</th>
                  <th>Match Date</th>
                  <th>Pool?</th>
                </tr>
              </thead>
              <tbody>
                {filteredMatches.map((match) => {
                  const urlPrefix = isPublic ? '/public' : ''
                  return (
                    <tr key={match.id}>
                      <td>{getTeamName(match.vbTeamId)}</td>
                      <td>
                        <NavLink to={urlPrefix + '/eventMatch/' + match.id}>
                          <strong>{match.opponentName}</strong>
                        </NavLink>
                        {match.sets.length > 0 && (
                          <div
                            className={
                              match.flash
                                ? 'animate__animated animate__flash'
                                : ''
                            }
                            id={`match-${match.id}-sets`}
                          >
                            {match.sets.map((set) => {
                              return getFormattedSetInfo(set)
                            })}
                          </div>
                        )}
                      </td>
                      <td>
                        {!isPublic && (
                          <BiEdit
                            title='Edit Match Info'
                            color='blue'
                            size='1rem'
                            onClick={() => {
                              setEditMatchId(match.id)
                              setShowMatchForm(true)
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </td>
                      <td>{match.matchDateUsShortDate || match.matchDate}</td>
                      <td>{match.isPoolPlayBool ? 'Yes' : 'No'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  )
}

export default VBEvent
