import React, { useState } from 'react'
import MyTextInput from './form/MyTextInput'
import MyScoreInput from './form/MyScoreInput'
import MyTimeInput from './form/MyTimeInput'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { addOrUpdateVBSet, getVBSet } from '../api/statsApi'
import { useEffect } from 'react'
import { messaging } from '../utils/toast'
import { Button } from 'semantic-ui-react'
import './VbSetForm.css'
import Ably from '../api/ablyApi'

const channel = Ably.channels.get('update-sets')

const VBSetForm = ({
  addSet,
  cancelSet,
  vbEventMatchId,
  defaultSetId = 1,
  setId = null,
  onClose = null
}) => {
  const defaultSet = {
    setId: defaultSetId,
    setTime: '',
    score: 0,
    opponentScore: 0
  }

  const [vbSet, setVbSet] = useState(defaultSet)
  const [focusSecond, setFocusSecond] = useState(false)

  useEffect(() => {
    if (setId) {
      getVBSet(setId).then((data) => {
        if (data.status === 'success') {
          setVbSet(data.data)
        } else {
          messaging.errorMessage(data.error.message)
        }
      })
    }
  }, [setId])

  return (
    <div className='mb-2' style={{ position: 'relative' }}>
      <div>
        <button
          title='Close'
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '0',
            right: '0'
          }}
        >
          X
        </button>
      </div>
      <Formik
        initialValues={vbSet}
        validationSchema={Yup.object({
          setId: Yup.number()
            .required('Set Id is required')
            .min(1, 'Set must be greater than 0'),
          setTime: Yup.string(),
          score: Yup.number(),
          opponentScore: Yup.number()
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const useValues = { ...values, vbEventMatchId: vbEventMatchId }
            addOrUpdateVBSet(useValues)
              .then((data) => {
                if (data.status === 'success') {
                  messaging.successMessage(data.statusMessage)
                  resetForm({
                    values: defaultSet
                  })
                  addSet({ ...useValues, id: data.data.id })
                  channel.publish('update-sets', { matchId: vbEventMatchId })
                } else {
                  messaging.errorMessage(data.error.message)
                }
              })
              .catch((err) => {
                messaging.errorMessage(err)
              })
          } catch (error) {
            messaging.errorMessage(error.message)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <>
            <h4>Enter Set Information</h4>
            <Form className='ui form'>
              <div style={{ display: 'flex' }}>
                <MyTextInput
                  className='mr-2'
                  style={{ width: '3.5rem' }}
                  type='number'
                  label='Set Id'
                  size='mini'
                  name='setId'
                />
                <MyTimeInput
                  label='Time '
                  name='setTime'
                  placeholder='Set time'
                />
              </div>
              <div style={{ display: 'flex' }}>
                <MyScoreInput
                  type='number'
                  size='mini'
                  name='score'
                  label='Our Score'
                  shouldFocus={true}
                  onEnterClicked={() => {
                    setFocusSecond(true)
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <MyScoreInput
                  type='number'
                  size='mini'
                  name='opponentScore'
                  label='Opponent Score'
                  shouldFocus={focusSecond}
                />
              </div>
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                size='mini'
                positive
                content='Save'
              />
              <Button
                size='mini'
                color='red'
                content='Cancel'
                onClick={cancelSet}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export default VBSetForm
