import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'
import { closeModal } from '../../store/modalSlice'

export default function ModalWrapper({
  children,
  size,
  header,
  cancelCallback = null
}) {
  const dispatch = useDispatch()
  return (
    <Modal
      open={true}
      onClose={() => dispatch(closeModal())}
      size={size}
      dimmer='blurring'
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0',
        margin: '0'
      }}
    >
      {header && (
        <Modal.Header>
          {' '}
          {header}{' '}
          {cancelCallback && (
            <Button
              onClick={() => {
                dispatch(closeModal())
                if (cancelCallback !== null) {
                  cancelCallback()
                }
              }}
              content='Cancel'
              style={{ position: 'relative', top: '-5px', float: 'right' }}
            />
          )}
        </Modal.Header>
      )}
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  )
}
