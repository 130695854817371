import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

const PublicNav = () => {
  return (
    <div style={{ display: 'flex', margin: '0 0 1rem 1rem' }}>
      <Button size='mini' as={NavLink} to={'/public/statsHome'}>
        Events
      </Button>
      <Button size='mini' as={NavLink} to={'/public/teams/'}>
        Teams
      </Button>
    </div>
  )
}

export default PublicNav
