import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Checkbox, Divider } from 'semantic-ui-react'
import { getVBTeamMatchesWithResults, getVBTeams } from '../api/statsApi'

const VBTeamStats = ({ isPublic = false, vbTeamId }) => {
  let currentEvent = ''
  const [matches, setMatches] = useState([])
  const [loading, setLoading] = useState(true)
  const [teams, setTeams] = useState([])
  const [filteredMatches, setFilteredMatches] = useState([])
  const [showPoolPlay, setShowPoolPlay] = useState(true)
  const [showRegularPlay, setShowRegularPlay] = useState(true)

  const getFormattedSetInfo = (set) => {
    const diff = +set.score - +set.opponentScore
    const setScore = `${set.score} - ${set.opponentScore}`
    const winOrLoss = diff > 0 ? 'W' : 'L'
    return (
      <span key={set.id} style={{ color: winOrLoss === 'W' ? 'green' : 'red' }}>
        ({setScore} - {winOrLoss}){' '}
      </span>
    )
  }

  const getTeamName = (teamId) => {
    const found = teams.filter((team) => {
      return +team.id === +teamId
    })
    if (found && found.length > 0) {
      return found[0].name
    }
    return ''
  }

  useEffect(() => {
    getVBTeams().then((data) => {
      if (data.status === 'success') {
        setTeams(data.data)
      }
    })
    if (vbTeamId) {
      getVBTeamMatchesWithResults(vbTeamId).then((data) => {
        if (data.status === 'success') {
          data.data.sort((a, b) => {
            if (a.eventData.name > b.eventData.name) {
              return 1
            }
            if (a.eventData.name < b.eventData.name) {
              return -1
            }
            if (a.matchDate > b.matchDate) {
              return 1
            }
            return -1
          })
          setMatches(data.data)
          setLoading(false)
        }
      })
    }
  }, [vbTeamId])

  useEffect(() => {
    let filtered = []
    filtered = matches.filter((match) => {
      if (match.isPoolPlayBool) {
        return showPoolPlay
      }
      if (!match.isPoolPlayBool) {
        return showRegularPlay
      }
      return true
    })
    setFilteredMatches(filtered)
  }, [matches, showPoolPlay, showRegularPlay])

  if (loading) return <h1>Loading Event...</h1>

  return (
    <div>
      <h1>Team: {getTeamName(vbTeamId)} </h1>
      <Divider />
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 className='mb-0 mr-1'>Matches</h2>

          <Checkbox
            className='m-1'
            label='Pool'
            checked={showPoolPlay}
            onClick={() => {
              setShowPoolPlay(!showPoolPlay)
            }}
          />
          <Checkbox
            className='m-1'
            label='Regular'
            checked={showRegularPlay}
            onClick={() => {
              setShowRegularPlay(!showRegularPlay)
            }}
          />
        </div>
      </div>

      <div className='m-1'>
        {filteredMatches.length === 0 && <h3>No Matches Found</h3>}
        {filteredMatches.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Opponent</th>
                <th>Match Date</th>
                <th>Pool?</th>
              </tr>
            </thead>
            <tbody>
              {filteredMatches.map((match) => {
                let showEvent = false
                if (match.eventData.name !== currentEvent) {
                  showEvent = true
                  currentEvent = match.eventData.name
                }
                return (
                  <React.Fragment key={match.id}>
                    {showEvent && (
                      <tr>
                        <td
                          colSpan={4}
                          style={{ borderBottom: '1px solid #ccc' }}
                        >
                          <strong>{currentEvent}</strong>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td style={{ verticalAlign: 'top' }}>
                        <strong>{match.opponentName}</strong>
                        {match.sets.length > 0 && (
                          <div
                            className={
                              match.flash
                                ? 'animate__animated animate__flash'
                                : ''
                            }
                            id={`match-${match.id}-sets`}
                          >
                            {match.sets.map((set) => {
                              return getFormattedSetInfo(set)
                            })}
                          </div>
                        )}
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        {match.matchDateUsShortDate || match.matchDate}
                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        {match.isPoolPlayBool ? 'Yes' : 'No'}
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default VBTeamStats
