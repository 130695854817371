import React from 'react'
import ReactDom from 'react-dom'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SVG from '../components/SVG'
import { setShowMainNav } from '../store/appSlice'
import { logOutUser } from '../store/userSlice'
import { messaging } from '../utils/toast'

function MainNav({ as }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const navigateTo = (to) => {
    dispatch(setShowMainNav(false))
    history.push(to)
  }

  const logoutHandler = () => {
    dispatch(setShowMainNav(false))
    messaging.successMessage('You have logged out!')
    dispatch(logOutUser())
    history.push('/')
  }

  return ReactDom.createPortal(
    <>
      <div className='portal-backdrop'></div>
      <div className='portal-modal animate__animated animate__fadeInDown'>
        <div className='portal-content'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                maxWidth: '700px'
              }}
            >
              {as === 'NAV' && (
                <button
                  style={{ position: 'absolute', top: '2rem', right: '2rem' }}
                  title='Close Menu'
                  onClick={() => {
                    dispatch(setShowMainNav(false))
                  }}
                >
                  X
                </button>
              )}
              <img
                style={{ width: '250px' }}
                src='/assets/img/olvb-logo-main.png'
                alt='olvb logo'
              />
              {as !== 'NAV' && (
                <h3
                  style={{
                    maxWidth: '350px',
                    margin: '0 0 1rem 0',
                    textAlign: 'center'
                  }}
                >
                  You have reached the admin portal for Omaha Lightning
                  Volleyball.
                </h3>
              )}
            </div>
            <div className='main-nav'>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/statsHome')
                }}
              >
                <SVG svgPath={'score-board-olvb-1.svg'} size='72px' />
                <div className='main-nav-desc'>View and edit team stats</div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/registration')
                }}
              >
                <SVG svgPath={'olvb-event-registration-1.svg'} size='72px' />
                <div className='main-nav-desc'>
                  View event registration data
                </div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/athleteRegistration')
                }}
              >
                <SVG svgPath={'olvb-athlete-registration-1.svg'} size='72px' />
                <div className='main-nav-desc'>
                  View athlete registration data
                </div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/Profile')
                }}
              >
                <SVG svgPath={'olvb-profile-1.svg'} size='72px' />
                <div className='main-nav-desc'>View/edit your profile info</div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/emailMonitor')
                }}
              >
                <SVG svgPath={'olvb-unsent-emails-1.svg'} size='72px' />
                <div className='main-nav-desc'>View unsent emails</div>
              </div>
              <div className='main-nav-item' onClick={logoutHandler}>
                <SVG svgPath={'olvb-logout-1.svg'} size='72px' />
                <div className='main-nav-desc'>Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('root')
  )
}

export default MainNav
