import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import NotFound from './views/NotFound'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppState, setIsLoading } from './store/appSlice'
import { logOutUser } from './store/userSlice'
import Tourney20210911 from './views/Tourney20210911'
import LeagueFall2022 from './views/LeagueFall2022'
import BasicPaypal from './views/BasicPaypal'
import LoginForm from './views/LoginForm'
import ProfileForm from './views/ProfileForm'
import EmailMonitor from './views/EmailMonitor'
import Home from './views/Home'
import AdminHome from './views/AdminHome'
import AdminRoute from './AdminRoute'
import EventRegistration from './views/EventRegistration'
import StatsHome from './views/StatsHome'
import VBEventView from './views/VBEventView'
import VBEventMatchView from './views/VBEventMatchView'
import PublicStatsHome from './views/PublicStatsHome'
import PublicVBEventView from './views/PublicVBEventView'
import PublicVBEventMatchView from './views/PublicVBEventMatchView'
import PublicRoute from './PublicRoute'
import { tokenCurrent } from './utils/userUtils'
import AthleteRegistrationEnter from './views/AthleteRegistrationEnter'
import AthleteRegistration from './views/AthleteRegistration'
import Teams from './views/Teams'
import TeamStats from './views/TeamStats'
import MainNav from './components/MainNav'
import Loading from './components/Loading'
import InactiveAlert from './components/modals/InactiveAlert'
import { desktopNotify } from './utils/desktopNotify'
import { getVBEventMatch } from './api/statsApi'
import Ably from './api/ablyApi'

function App() {
  const appState = useSelector(selectAppState)
  const dispatch = useDispatch()

  useEffect(() => {
    const channel = Ably.channels.get('update-sets')
    channel.subscribe('update-sets', (resp) => {
      getVBEventMatch(resp.data.matchId).then((res) => {
        const msg = desktopNotify(
          'Match against ' + res.data.opponentName + ' was updated!',
          'match-updates-' + resp.data.matchId
        )
        if (msg) {
          msg.onclick = () => {
            window.location.href = '/public/eventMatch/' + msg
          }
        }
      })
    })

    if (window.Notification) {
      if (Notification.permission === 'default') {
        Notification.requestPermission()
      }
    }

    return () => {
      channel.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!tokenCurrent()) {
      dispatch(logOutUser())
    }
    setTimeout(() => {
      dispatch(setIsLoading(false))
    }, 1000)
  }, [appState.isLoading, dispatch])

  if (appState.isLoading) {
    return <Loading />
  }

  return (
    <>
      <ToastContainer
        draggable={false}
        closeOnClick={false}
        position='top-center'
        autoClose={3000}
      />
      <Router>
        {appState.showMainNav && <MainNav as='NAV' />}
        {appState.showInactiveAlert && <InactiveAlert />}
        <div style={{ marginBottom: '1.75rem' }}>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route exact path='/login'>
              <LoginForm />
            </Route>
            <Route exact path='/paypal'>
              <BasicPaypal />
            </Route>
            <Route exact path='/Tourney2021' component={Tourney20210911} />
            <Route exact path='/LeagueFall2022' component={LeagueFall2022} />
            <Route
              exact
              path='/athleteRegistrationEnter'
              component={AthleteRegistrationEnter}
            />
            <AdminRoute path='/profile' component={ProfileForm} />
            <AdminRoute path='/adminHome' component={AdminHome} />
            <AdminRoute path='/emailMonitor' component={EmailMonitor} />
            <AdminRoute path='/statsHome' component={StatsHome} />
            <AdminRoute path='/statsEvent/:id' component={VBEventView} />
            <AdminRoute path='/eventMatch/:id' component={VBEventMatchView} />
            <AdminRoute
              exact
              path='/registration'
              component={EventRegistration}
            />
            <AdminRoute
              path='/registration/:id'
              component={EventRegistration}
            />
            <AdminRoute
              path='/athleteRegistration'
              component={AthleteRegistration}
            />
            <PublicRoute path='/public/statsHome' component={PublicStatsHome} />
            <PublicRoute
              path='/public/statsEvent/:id'
              component={PublicVBEventView}
            />
            <PublicRoute
              path='/public/eventMatch/:id'
              component={PublicVBEventMatchView}
            />
            <PublicRoute exact path='/public/teams' component={Teams} />
            <PublicRoute path='/public/teams/:id' component={TeamStats} />
            <Route path='*' component={NotFound} />
          </Switch>
        </div>
      </Router>
    </>
  )
}

export default App
