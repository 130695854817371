import React from 'react'
import { useHistory } from 'react-router-dom'
import SVG from '../components/SVG'

function Home() {
  const history = useHistory()
  const navigateTo = (to) => {
    history.push(to)
  }

  return (
    <>
      <div className='portal-backdrop'></div>
      <div className='portal-modal animate__animated animate__fadeInDown'>
        <div className='portal-content'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: '250px' }}
                src='/assets/img/olvb-logo-main.png'
                alt='olvb logo'
              />
              <h3
                style={{
                  maxWidth: '350px',
                  margin: '0 0 1rem 0',
                  textAlign: 'center'
                }}
              >
                You have reached the portal for Omaha Lightning Volleyball.
              </h3>
            </div>
            <div className='main-nav'>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/login')
                }}
              >
                <SVG svgPath={'olvb-padlock.svg'} size='72px' />
                <div className='main-nav-desc'>Log in to enter team data</div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/public/statsHome')
                }}
              >
                <SVG svgPath={'score-board-olvb-1.svg'} size='72px' />
                <div className='main-nav-desc'>View match results</div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  navigateTo('/paypal')
                }}
              >
                <SVG svgPath={'olvb-payments-1.svg'} size='72px' />
                <div className='main-nav-desc'>Make payments</div>
              </div>
              <div
                className='main-nav-item'
                onClick={() => {
                  window.open('https://omahalightningvolleyball.com')
                }}
              >
                <SVG svgPath={'vball-serve-1.svg'} size='72px' />
                <div className='main-nav-desc'>Omaha Lightning Website</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
