import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logOutUser } from './store/userSlice'
import NSF from './views/NSF'
import { refreshToken } from './api/userApi'
import { tokenCurrent } from './utils/userUtils'
import styled from 'styled-components'
import { setShowInactiveAlert } from './store/appSlice'
import Header from './components/Header'

const AdminRoute = ({
  component: Component,
  role = 'ContentManagement',
  ...rest
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [isNSF, setIsNSF] = useState(false)

  const timeoutRef = useRef()

  useEffect(() => {
    if (!tokenCurrent()) {
      setIsAuthenticated(false)
      dispatch(logOutUser())
      history.push('/login')
    } else {
      if (
        user.roles &&
        !(user.roles.includes('WebAdmin') || user.roles.includes(role))
      ) {
        setIsNSF(true)
      } else {
        setIsNSF(false)
      }
      setIsAuthenticated(true)
    }

    refreshToken()

    timeoutRef.current = setInterval(() => {
      if (!tokenCurrent()) {
        dispatch(logOutUser())
        dispatch(setShowInactiveAlert(true))
        setIsAuthenticated(false)
      }
    }, 60000)

    return () => {
      clearInterval(timeoutRef.current)
    }

    // eslint-disable-next-line
  }, [user, dispatch, history.location, role])

  if (isAuthenticated === null) {
    return <></>
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Redirect to='/login' />
        ) : (
          <AdminWrapper>
            <AdminContent>
              <Header />
              <div style={{ padding: '0 1rem' }}>
                {isNSF && <NSF />}
                {!isNSF && <Component {...props} {...rest} />}
              </div>
            </AdminContent>
          </AdminWrapper>
        )
      }
    />
  )
}

export default AdminRoute

const AdminWrapper = styled.div`
  background-color: #fff;
  margin: 0 auto;
  min-width: 350px;
  max-width: 70rem;
  min-height: 35rem;
  position: relative;
  margin-top: 4rem;
`

const AdminContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.5rem;
  background-color: white;
  min-height: 45rem;
  > div {
    padding: 1.25rem;
  }
`
