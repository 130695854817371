import React from 'react'
import { NavLink } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import PublicNav from './components/PublicNav'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PublicWrapper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0 1rem 0 1rem'
            }}
          >
            <h1 className='my-1'>Omaha Lightning Stats</h1>
            <Button size='tiny' as={NavLink} to='/'>
              Back to portal
            </Button>
          </div>
          <PublicNav />
          <PublicContent>
            <div style={{ padding: '0 1rem' }}>
              <Component {...props} {...rest} />
            </div>
          </PublicContent>
        </PublicWrapper>
      )}
    />
  )
}

export default PublicRoute

const PublicWrapper = styled.div`
  background-color: #fff;
  margin: 0 auto;
  min-width: 25rem;
  max-width: 65rem;
  min-height: 35rem;
  position: relative;
`

const PublicContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 50rem;
  max-width: 60rem;
  > div {
    padding: 1.25rem;
  }
`
