import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: true,
    sideBarOpen: false,
    showMainNav: false,
    showInactiveAlert: false
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSideBarOpen: (state, action) => {
      state.sideBarOpen = action.payload
    },
    setShowMainNav: (state, action) => {
      state.showMainNav = action.payload
    },
    setShowInactiveAlert: (state, action) => {
      state.showInactiveAlert = action.payload
    }
  }
})

export const {
  setIsLoading,
  setSideBarOpen,
  setShowMainNav,
  setShowInactiveAlert
} = appSlice.actions

export const selectAppState = (state) => state.app

export default appSlice.reducer
