import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import PayPal from '../components/PayPal'
import PaypalPaymentSuccess from '../components/PaypalPaymentSuccess'
import api from '../api/axiosConfig'

const BasicPaypal = () => {
  const [showSuccess, setShowSuccess] = useState(false)

  if (showSuccess) {
    return <PaypalPaymentSuccess />
  }

  return (
    <div className='page-wrapper' style={{ marginTop: '1rem' }}>
      <div
        style={{
          maxWidth: '35rem',
          margin: '.75rem auto 0 auto',
          padding: '.75rem .75rem 0 .75rem'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h4 style={{ margin: '0', padding: '0' }}>
            Omaha Lightning PayPal Payments
          </h4>
          <span>
            <Button size='tiny' as={NavLink} to='/'>
              Back to portal
            </Button>
          </span>
        </div>
        <h5>Enter the amount you wish to pay via PayPal below.</h5>
        <p>
          You may enter more than one item to pay for. Each time you click Add
          Item, a new payment item will be added to the list. When done adding
          items click on one of the PayPal buttons below. You must enter both an
          amount and a description in order to be able to add an item.
        </p>
      </div>
      <PayPal
        showAddPaymentSelectBox={false}
        onPaymentSuccess={(details, data, payments) => {
          const paymentsData = payments.map((pmt) => {
            return {
              amount: pmt.amount.value,
              description: pmt.amount.description
            }
          })
          const submitData = {
            orderId: data.orderID,
            firstName: details.payer.name.given_name,
            lastName: details.payer.name.surname,
            email: details.payer.email_address,
            payments: paymentsData
          }

          api.post('/EmailService/paypalPaymentReceived', submitData)
          setShowSuccess(true)
        }}
      />
    </div>
  )
}

export default BasicPaypal
