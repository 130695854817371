import React from 'react'

const SVG = ({ svgPath, size, ...rest }) => {
  return (
    <div className={rest?.className}>
      <img src={`/assets/svg/${svgPath}`} style={{ width: size }} alt='logo' />
    </div>
  )
}

export default SVG
