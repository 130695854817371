import { useTable, useSortBy } from 'react-table'
import {
  TiArrowUnsorted,
  TiArrowSortedUp,
  TiArrowSortedDown
} from 'react-icons/ti'

export const Table = ({ columns, data, classToUse }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            const rowOneColumn = row1.values[columnName]
            const rowTwoColumn = row2.values[columnName]
            if (isNaN(rowOneColumn)) {
              return rowOneColumn.toUpperCase().trim() >
                rowTwoColumn.toUpperCase().trim()
                ? 1
                : -1
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1
          }
        },
        columns,
        data
      },
      useSortBy
    )

  // Render the UI for your table
  return (
    <table className={classToUse} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TiArrowSortedDown
                        color='rgb(64,128,255)'
                        style={{
                          margin: '0 0 0 3px',
                          isolation: 'isolate',
                          position: 'relative',
                          top: '2px'
                        }}
                      />
                    ) : (
                      <TiArrowSortedUp
                        color='rgb(64,128,255)'
                        style={{
                          margin: '0 0 0 3px',
                          isolation: 'isolate',
                          position: 'relative',
                          top: '2px'
                        }}
                      />
                    )
                  ) : (
                    <TiArrowUnsorted
                      color='rgb(64,128,255)'
                      style={{
                        margin: '0 0 0 3px',
                        isolation: 'isolate',
                        position: 'relative',
                        top: '2px'
                      }}
                    />
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
