import api from './axiosConfig'

const rootStatsAdminUrl = '/StatsAdmin'
const rootStatsPublicUrl = '/StatsPublic'

export const getVBEvents = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getVBEvents')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBEvent = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getVBEvent/' + eventId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const addOrUpdateVBEvent = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootStatsAdminUrl + '/addOrUpdateVBEvent',
        data
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBTeams = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getVBTeams')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBTeam = (teamId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getVBTeam/' + teamId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const addOrUpdateVBSet = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(rootStatsAdminUrl + '/addOrUpdateVBSet', data)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBSets = (matchId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getVBSets/' + matchId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBSet = (setId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getVBSet/' + setId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const deleteVBSet = (setId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(rootStatsAdminUrl + '/deleteVBSet/' + setId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBEventMatches = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootStatsPublicUrl + '/getVBEventMatches/' + eventId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBEventMatchesWithResults = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootStatsPublicUrl + '/getVBEventMatchesWithResults/' + eventId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBTeamMatchesWithResults = (teamId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootStatsPublicUrl + '/getVBTeamMatchesWithResults/' + teamId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBEventMatch = (matchId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootStatsPublicUrl + '/getVBEventMatch/' + matchId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getVBEventMatchWithResults = (matchId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootStatsPublicUrl + '/getVBEventMatchWithResults/' + matchId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const addOrUpdateVBEventMatch = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootStatsAdminUrl + '/addOrUpdateVBEventMatch',
        data
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getOpponents = (eventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootStatsPublicUrl + '/getOpponents/' + eventId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}
