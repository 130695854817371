import React from 'react'
import { NavLink } from 'react-router-dom'

const EventsNavBar = ({ urlPrevix = '', eventId = null }) => {
  return (
    <div style={{ display: 'flex' }}>
      <NavLink to={urlPrevix + '/statsHome'}>
        Events {eventId !== null && '>> '}
      </NavLink>
      {eventId !== null && (
        <NavLink to={urlPrevix + '/statsEvent/' + eventId}>Matches</NavLink>
      )}
    </div>
  )
}

export default EventsNavBar
