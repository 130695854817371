import React, { useState } from 'react'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getVBTeams } from '../api/statsApi'

const VBTeams = ({ isPublic = false }) => {
  const [teams, setTeams] = useState([])

  useEffect(() => {
    let isMounted = true
    getVBTeams().then((data) => {
      if (isMounted) {
        setTeams(data.data)
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className='mr-1'>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}></div>

      <div className='m-2'>
        {teams.length === 0 && <h3>No Teams Found</h3>}
        {teams.length > 0 &&
          teams.map((team) => {
            return <div key={team.id}>{team.event}</div>
          })}
      </div>
      <div className='mr-1'>
        <table>
          <thead>
            <tr>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team) => {
              return (
                <tr key={team.id}>
                  <td>
                    <NavLink
                      to={(isPublic ? '/public' : '') + '/teams/' + team.id}
                    >
                      <strong>{team.name}</strong>
                    </NavLink>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VBTeams
